import { Component, OnInit, AfterViewInit, AfterViewChecked, AfterContentInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { IUser } from 'app/core/models/User';
import { AuthService } from 'app/core/services/auth.service';

//Metadata
export interface RouteInfo {
    path: string;
    title: string;
    type: string;
    collapse?: string;
    icontype: string;
    // icon: string;
    children?: ChildrenItems[];
}

export interface ChildrenItems {
    path: string;
    title: string;
    ab: string;
    type?: string;
}

//Menu Items
export const ROUTES: RouteInfo[] = [{
    path: '/dashboard',
    title: 'Dashboard',
    type: 'link',
    icontype: 'nc-icon nc-layout-11'
},
{
    path: '/importacao',
    title: 'Importação',
    type: 'sub',
    collapse: 'importacao',
    icontype: 'nc-icon nc-delivery-fast',
    children: [
        { path: 'relatorioPos', title: 'PO', ab: 'P'},
        { path: 'novo-processo', title: 'Novo Processo', ab: 'N' },
        { path: 'processos', title: 'Processos', ab: 'P' },
        { path: 'sefaz', title: 'Sefaz', ab: 'S' },
    ]
},
{
    path: '/configuracoes',
    title: 'Configurações',
    type: 'sub',
    collapse: 'configuracoes',
    icontype: 'nc-icon nc-settings-gear-65',
    children: [
        { path: 'representante', title: 'Representante Legal', ab: 'R' },
        { path: 'associados', title: 'Importador', ab: 'I' },
    ]
},
{
    path: '/dci',
    title: 'DCI',
    type: 'sub',
    collapse: 'dci',
    icontype: 'nc-icon nc-app',
    children: [
        { path: 'novo-processo-dci', title: 'Novo Processo', ab: 'N' },
        { path: 'processos-dci', title: 'Meus Processos', ab: 'P' },
    ]
},
{
    path: '/dcr',
    title: 'DCR',
    type: 'sub',
    collapse: 'dcr',
    icontype: 'nc-icon nc-app',
    children: [
        { path: 'novo-processo-dcr', title: 'Novo Processo', ab: 'N' },
        { path: 'processos-dcr', title: 'Meus Processos', ab: 'P' },
    ]
},


    // {
    //     path: '/components',
    //     title: 'Components',
    //     type: 'sub',
    //     collapse: 'components',
    //     icontype: 'nc-icon nc-layout-11',
    //     children: [
    //         { path: 'buttons', title: 'Buttons', ab: 'B' },
    //         { path: 'grid', title: 'Grid System', ab: 'GS' },
    //         { path: 'panels', title: 'Panels', ab: 'P' },
    //         { path: 'sweet-alert', title: 'Sweet Alert', ab: 'SA' },
    //         { path: 'notifications', title: 'Notifications', ab: 'N' },
    //         { path: 'icons', title: 'Icons', ab: 'I' },
    //         { path: 'typography', title: 'Typography', ab: 'T' }
    //     ]
    // },

    // {
    //     path: '/forms',
    //     title: 'Forms',
    //     type: 'sub',
    //     collapse: 'forms',
    //     icontype: 'nc-icon nc-ruler-pencil',
    //     children: [
    //         { path: 'regular', title: 'Regular Forms', ab: 'RF' },
    //         { path: 'extended', title: 'Extended Forms', ab: 'EF' },
    //         { path: 'validation', title: 'Validation Forms', ab: 'VF' },
    //         { path: 'wizard', title: 'Wizard', ab: 'W' }
    //     ]
    // }, {
    //     path: '/tables',
    //     title: 'Tables',
    //     type: 'sub',
    //     collapse: 'tables',
    //     icontype: 'nc-icon nc-single-copy-04',
    //     children: [
    //         { path: 'regular', title: 'Regular Tables', ab: 'RT' },
    //         { path: 'extended', title: 'Extended Tables', ab: 'ET' },
    //         { path: 'datatables.net', title: 'Datatables.net', ab: 'DT' }
    //     ]
    // }, {
    //     path: '/maps',
    //     title: 'Maps',
    //     type: 'sub',
    //     collapse: 'maps',
    //     icontype: 'nc-icon nc-pin-3',
    //     children: [
    //         { path: 'google', title: 'Google Maps', ab: 'GM' },
    //         { path: 'fullscreen', title: 'Full Screen Map', ab: 'FSM' },
    //         { path: 'vector', title: 'Vector Map', ab: 'VM' }
    //     ]
    // }, {
    //     path: '/widgets',
    //     title: 'Widgets',
    //     type: 'link',
    //     icontype: 'nc-icon nc-box'

    // }, {
    //     path: '/charts',
    //     title: 'Charts',
    //     type: 'link',
    //     icontype: 'nc-icon nc-chart-bar-32'

    // }, {
    //     path: '/calendar',
    //     title: 'Calendar',
    //     type: 'link',
    //     icontype: 'nc-icon nc-calendar-60'
    // }, {
    //     path: '/pages',
    //     title: 'Pages',
    //     collapse: 'pages',
    //     type: 'sub',
    //     icontype: 'nc-icon nc-book-bookmark',
    //     children: [
    //         { path: 'timeline', title: 'Timeline Page', ab: 'T' },
    //         { path: 'user', title: 'User Page', ab: 'UP' },
    //         { path: 'login', title: 'Login Page', ab: 'LP' },
    //         { path: 'register', title: 'Register Page', ab: 'RP' },
    //         { path: 'lock', title: 'Lock Screen Page', ab: 'LSP' }
    //     ]
    // }
];

@Component({
    moduleId: module.id,
    selector: 'sidebar-cmp',
    templateUrl: 'sidebar.component.html',
})

export class SidebarComponent {

    public codeEmpresa: number = 0;
    public user: IUser;
    public nomeuser: string = "";
    public nomeempresa: string = "";
    public step: string = "";

    constructor(
        private authservice: AuthService
    ) {

    }

    onSelectStep(item) {
        this.step = item;
    }

    onClearStep() {
        this.step = "";
    }

    public menuItems: any[];
    isNotMobileMenu() {
        if (window.outerWidth > 991) {
            return false;
        }
        return true;
    }

    ngOnInit() {
        this.user = this.authservice.CurrentUser;
        this.user.name.length > 15 ? this.nomeuser = this.user.name.substring(0, 15) : this.nomeuser = this.user.name
        this.nomeempresa = this.user.empresa.nomeFantasia;

        this.codeEmpresa = this.authservice.CurrentUser.idEmpresa;
        this.menuItems = ROUTES.filter(menuItem => menuItem);
    }
    ngAfterViewInit() {
    }
}
