import { HttpClient, HttpErrorResponse } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { environment } from 'environments/environment';
import { Observable, catchError, delay, tap, throwError, map, of } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class GenericHttpService {

  protected readonly apiUrl = `${environment.baseUrlBackend}`;

  constructor(private http: HttpClient) { }

  get(endpoint: string, args?: any): Observable<any> {
    //const pagination = args || { page: 1, limit: 10 };
    const url = `${this.apiUrl}/${endpoint}`;
    return this.http.get<any>(url).pipe(
      tap({
        next: val => { },
        error: error => {
          console.log('on error', error.message);
        },
        complete: () => { }
      })
    );
  }

  getById(endpoint: string, arg?: any): Observable<any> {
    const url = `${this.apiUrl}/${endpoint}/${arg}`;
    return this.http.get<any>(url).pipe(
      tap({
        next: val => { },
        error: error => {
          console.log('on error', error.message);
        },
        complete: () => { }
      })
    );
  }

  post(endpoint: string, data: any): Observable<any> {
    const url = `${this.apiUrl}/${endpoint}`;
    return this.http.post<any>(url, data).pipe(
      catchError(this.handleError)
    );
  }

  put(endpoint: string, data: any): Observable<any> {
    const url = `${this.apiUrl}/${endpoint}`;
    return this.http.put<any>(url, data).pipe(
      catchError(this.handleError)
    );
  }

  delete(endpoint: string, id: number): Observable<any> {
    const url = `${this.apiUrl}/${endpoint}/${id}`;
    return this.http.delete<any>(url).pipe(
      catchError(this.handleError)
    );
  }

  public getByGenericQ(term: string, endpoint: string,): Observable<any> {
    if (term) {
      return this.http.get<any>(`${environment.baseUrlBackend}/api/${endpoint}?q=${term}`)
        .pipe(
          map(rsp => {
            if (rsp.Error) {
              throwError(rsp.Error);
            } else {
              rsp.forEach((element) => {
                if (endpoint === 'Item/GetByPartnumberQ')
                  element.codigo_nome = element.partNumber + " - " + element.descricao
                else if (endpoint === 'Fornecedor/GetByQ') {
                  element.codigo_nome = element.razaoSocial + " - " + element.pais.nome
                }
                else
                  element.codigo_nome = element.codigo + " - " + element.nome
              });
              return rsp
            }
          })
        );
    } else {
      return of([]);
    }
  }

  public getGenericLookups(endpoint: string): Observable<any> {
    return this.http.get<any>(`${environment.baseUrlBackend}/${endpoint}`).pipe(
      map(rsp => {
        if (rsp.Error) {
          throwError(rsp.Error);
        } else {
          rsp.forEach((element) => {
            element.codigo_nome = element.codigo + " - " + element.nome
          });
          return rsp
        }
      })
    );
  }

  postFile(endpoint: string, file: File, bucket: string): Observable<any> {
    const formData = new FormData();
    formData.append('file', file);

    const url = `${this.apiUrl}/${endpoint}?Bucket=${bucket}`;
    return this.http.post<any>(url, formData).pipe(
      catchError(this.handleError)
    );
  }

  private handleError(error: HttpErrorResponse) {
    return throwError(() => error);
  }
}
