<nav #navbar class="navbar navbar-expand-lg navbar-absolute" [ngClass]="{ 'bg-white': open === true , 'navbar-transparent': open === false}">
  <div class="container-fluid">
    <div class="navbar-wrapper">
      <div class="navbar-minimize">

        <button id="minimizeSidebar" class="btn btn-icon btn-round" (click)="minimizeSidebar()">
          <i class="nc-icon nc-minimal-right text-center visible-on-sidebar-mini"></i>
          <i class="nc-icon nc-minimal-left text-center visible-on-sidebar-regular"></i>
        </button>
        
      </div>
      <div class="navbar-toggle" (click)="sidebarToggle()">
        <button type="button" class="navbar-toggler">
          <span class="navbar-toggler-bar bar1"></span>
          <span class="navbar-toggler-bar bar2"></span>
          <span class="navbar-toggler-bar bar3"></span>
        </button>
      </div>
      <a class="navbar-brand" href="javascript:void(0)">{{getTitle()}}</a>
    </div>
    <button class="navbar-toggler" type="button" data-toggle="collapse" data-target="#navigation" aria-controls="navigation-index" aria-expanded="false" aria-label="Toggle navigation" (click)="open = !open"
      >
      <span class="navbar-toggler-bar navbar-kebab"></span>
      <span class="navbar-toggler-bar navbar-kebab"></span>
      <span class="navbar-toggler-bar navbar-kebab"></span>
    </button>
    <div class="collapse navbar-collapse justify-content-end" id="navigation">
      <!-- <form>
        <div class="input-group no-border">
          <input type="text" value="" class="form-control" placeholder="Search...">
          <div class="input-group-append">
            <div class="input-group-text">
              <i class="nc-icon nc-zoom-split"></i>
            </div>
          </div>
        </div>
      </form> -->
      <ul class="navbar-nav">
        <ng-container *ngFor="let item of listMoedas">
          <li class="nav-item">
            <a class="nav-link btn-magnify" href="javascript:void(0)">
             <b>{{item.sigla}}</b> {{formatCurrency(item.valorTaxa) }}
            </a>
          </li>
        </ng-container>
      
        <!-- <li class="nav-item">
          <a class="nav-link btn-magnify" href="javascript:void(0)">
           <b>EUR</b> 0,00
          </a>
        </li>
        <li class="nav-item">
          <a class="nav-link btn-magnify" href="javascript:void(0)">
            <b>NOK</b> 0,00
          </a>
        </li> -->
       <!--  <li class="nav-item btn-rotate dropdown">
          <a class="nav-link dropdown-toggle" href="pablo" id="navbarDropdownMenuLink" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
            <i class="nc-icon nc-bell-55"></i>
            <p>
              <span class="d-lg-none d-md-block">Some Actions</span>
            </p>
          </a>
          <div class="dropdown-menu dropdown-menu-right" aria-labelledby="navbarDropdownMenuLink">
            <a class="dropdown-item" href="javascript:void(0)">Action</a>
            <a class="dropdown-item" href="javascript:void(0)">Another action</a>
            <a class="dropdown-item" href="javascript:void(0)">Something else here</a>
          </div>
        </li> -->
        <li class="nav-item btn-rotate dropdown">
          <a class="nav-link dropdown-toggle" id="navbarDropdownMenuLinkSet" href="javascript:void(0)" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
            <i class="nc-icon nc-settings-gear-65"></i>
            <p>
              <span class="d-lg-none d-md-block">Account</span>
            </p>
          </a>
          <div class="dropdown-menu dropdown-menu-right" aria-labelledby="navbarDropdownMenuLinkSet">
            <a class="dropdown-item" href="javascript:void(0)" (click)="onSubmitLogout()">Logout</a>
          </div>
        </li>
      </ul>
    </div>
  </div>
</nav>
