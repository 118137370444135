<div class="logo">
    <a href="javascript:void(0)" class="simple-text logo-mini">
        <div class="logo-image-small">
            <img src="./assets/img/globo.png" />
        </div>
    </a>
    <a href="javascript:void(0)" class="simple-text logo-normal">
        <b>{{this.nomeempresa}}</b>
    </a>
</div>

<div class="sidebar-wrapper">

    <div class="user">
        <div class="photo">
            <i class="fa fa-user-circle " style="font-size:34px;color:#FFFF;" aria-hidden="true"></i>
        </div>
       
        <div class="info">
            <a data-toggle="collapse" href="#collapseExample" class="collapsed" [routerLink]="'/configuracoes/minha-conta'">
                <span>
                    <b>{{nomeuser}}</b>
                </span>
            </a>
            <div class="clearfix"></div>
          
        </div>
    </div>

    <ul class="nav">
        <li routerLinkActive="active" *ngFor="let menuitem of menuItems">
            <!--If is a single link-->
            <a [routerLink]="[menuitem.path]" *ngIf="menuitem.type === 'link'" (click)="onClearStep()">
                <i class="{{menuitem.icontype}}"></i>
                <p>{{menuitem.title}}</p>
            </a>
            <!--If it have a submenu-->
            <a data-toggle="collapse" href="#{{menuitem.collapse}}" *ngIf="menuitem.type === 'sub'">
                <i class="{{menuitem.icontype}}"></i>
                <p>{{menuitem.title}}<b class="caret"></b></p>
            </a>

            <!--Display the submenu items-->
            <div id="{{menuitem.collapse}}" class="collapse" *ngIf="menuitem.type === 'sub'">
                <ul class="nav">
                    <li [ngClass]="{'active': step === childitem.path}" (click)="onSelectStep(childitem.path)"
                        *ngFor="let childitem of menuitem.children">

                        <a *ngIf="childitem.path === 'representante'"
                            [routerLink]="[menuitem.path, childitem.path, codeEmpresa]">
                            <span class="sidebar-mini-icon">{{childitem.ab}}</span>
                            <span class="sidebar-normal">{{childitem.title}}</span>
                        </a>

                        <a *ngIf="childitem.path === 'associados'"
                            [routerLink]="[menuitem.path, 'empresa', codeEmpresa , childitem.path]">
                            <span class="sidebar-mini-icon">{{childitem.ab}}</span>
                            <span class="sidebar-normal">{{childitem.title}}</span>
                        </a>

                        <a *ngIf="childitem.path !== 'representante'
                            && childitem.path !== 'associados'" [routerLink]="[menuitem.path, childitem.path]">
                            <span class="sidebar-mini-icon">{{childitem.ab}}</span>
                            <span class="sidebar-normal">{{childitem.title}}</span>
                        </a>
                    </li>
                </ul>
            </div>
        </li>
    </ul>
</div>