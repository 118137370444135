import { Component } from '@angular/core';

@Component({
    selector: 'app-layout',
    templateUrl: './auth-layout.component.html'
})

export class AuthLayoutComponent {
    ngOnInit() {
    }
}
