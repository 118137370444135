<footer class="footer">
    <div class="container-fluid">
        <nav class="pull-left">
            <ul>

                <li>
                    <a href="https://ganeden.com.br/">
                        Gan Eden Comex
                    </a>
                </li>
            
                <li>
                    <a href="https://ganeden.com.br/">
                        Licenses
                    </a>
                </li>
            </ul>
        </nav>
        <div class="copyright pull-right">
            &copy; {{test | date: 'yyyy'}}, made with <i class="fa fa-heart heart"></i> by <a href="https://ganeden.com.br/">Ganeden - Consultancy and Technology</a>
        </div>
    </div>
</footer>
