import { Routes } from '@angular/router';
import { AuthGuard } from './guards/auth-guard';

import { AdminLayoutComponent } from './layouts/admin/admin-layout.component';
import { AuthLayoutComponent } from './layouts/auth/auth-layout.component';

export const AppRoutes: Routes = [
    {
        path: '',
        redirectTo: 'pages/login',
        pathMatch: 'full',
    }, {
        path: '',
        component: AdminLayoutComponent,
        canActivate: [AuthGuard],
        children: [
            {
                path: '',
                loadChildren: () => import('./dashboard/dashboard.module').then(x => x.DashboardModule)
            },
            {
                path: 'importacao',
                loadChildren: () => import('./importacao/importacao.module').then(x => x.ImportacaoModule)
            },
            {
                path: 'configuracoes',
                loadChildren: () => import('./configuracoes/configuracoes.module').then(x => x.ConfiguracoesModule)
            },
            {
                path: 'components',
                loadChildren: () => import('./components/components.module').then(x => x.ComponentsModule)
            },{
                path: '',
                loadChildren: () => import('./userpage/user.module').then(x => x.UserModule)
            }]
    }, {
        path: '',
        component: AuthLayoutComponent,
        children: [{
            path: 'pages',
            loadChildren: () => import('./pages/pages.module').then(x => x.PagesModule)
        }]
    }
];
