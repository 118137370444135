import { Component, OnInit, Renderer2, ViewChild, ElementRef } from '@angular/core';
import { ROUTES } from '../.././sidebar/sidebar.component';
import { Router, NavigationEnd } from '@angular/router';
import { Location } from '@angular/common';
import { filter, Subscription } from 'rxjs';
import { AuthService } from 'app/core/services/auth.service';
import { GenericHttpService } from 'app/importacao/services/generic-http.service';

var misc: any = {
    navbar_menu_visible: 0,
    active_collapse: true,
    disabled_collapse_init: 0,
}

@Component({
    moduleId: module.id,
    selector: 'navbar-cmp',
    templateUrl: 'navbar.component.html'
})

export class NavbarComponent implements OnInit {
    public listMoedas: any[];
    private listTitles: any[];
    location: Location;
    private nativeElement: Node;
    private toggleButton;
    private sidebarVisible: boolean;
    private _router: Subscription;
    public open: boolean = false;

    @ViewChild("navbar-cmp", { static: false }) button;

    constructor(
        location: Location,
        private genericHttpService: GenericHttpService,
        private renderer: Renderer2,
        private element: ElementRef,
        private router: Router,
        private serviceAuth: AuthService) {
        this.location = location;
        this.nativeElement = element.nativeElement;
        this.sidebarVisible = false;
    }

    ngOnInit() {

        this.onGetMoedas()

        this.listTitles = ROUTES.filter(listTitle => listTitle);

        const navbar: HTMLElement = this.element.nativeElement;
        const body = document.getElementsByTagName('body')[0];
        this.toggleButton = navbar.getElementsByClassName('navbar-toggler')[0];

        body.classList.add('sidebar-mini');

        if (body.classList.contains('sidebar-mini')) {
            misc.sidebar_mini_active = true;
        }
        this._router = this.router.events.pipe(filter(event => event instanceof NavigationEnd)).subscribe((event: NavigationEnd) => {
            const $layer = document.getElementsByClassName('close-layer')[0];
            if ($layer) {
                $layer.remove();
            }
        });
    }

    minimizeSidebar() {
        const body = document.getElementsByTagName('body')[0];

        console.log(misc.sidebar_mini_active)

        if (misc.sidebar_mini_active === true) {

            body.classList.remove('sidebar-mini');
            misc.sidebar_mini_active = false;

        } else {
            setTimeout(function () {
                body.classList.add('sidebar-mini');

                misc.sidebar_mini_active = true;
            }, 100);
        }

        // // we simulate the window Resize so the charts will get updated in realtime.
        const simulateWindowResize = setInterval(function () {
            window.dispatchEvent(new Event('resize'));
        }, 180);

        // we stop the simulation of Window Resize after the animations are completed
        setTimeout(function () {
            clearInterval(simulateWindowResize);
        }, 1000);
    }

    isMobileMenu() {
        // if (window.outerWidth < 991) {
        //     return false;
        // }
        // return true;
    }

    sidebarOpen() {

        // var toggleButton = this.toggleButton;
        // var html = document.getElementsByTagName('html')[0];
        // setTimeout(function () {
        //     toggleButton.classList.add('toggled');
        // }, 500);
        // const mainPanel = <HTMLElement>document.getElementsByClassName('main-panel')[0];
        // if (window.innerWidth < 991) {
        //     mainPanel.style.position = 'fixed';
        // }
        // html.classList.add('nav-open');
        // this.sidebarVisible = true;
    }
    sidebarClose() {
        // var html = document.getElementsByTagName('html')[0];
        // this.toggleButton.classList.remove('toggled');
        // this.sidebarVisible = false;
        // html.classList.remove('nav-open');
        // const mainPanel = <HTMLElement>document.getElementsByClassName('main-panel')[0];

        // if (window.innerWidth < 991) {
        //     setTimeout(function () {
        //         mainPanel.style.position = '';
        //     }, 500);
        // }
    }
    sidebarToggle() {
        // if (this.sidebarVisible == false) {
        //     this.sidebarOpen();
        // } else {
        //     this.sidebarClose();
        // }
    }

    getTitle() {
        var titlee = this.location.prepareExternalUrl(this.location.path());
        if (titlee.charAt(0) === '#') titlee = titlee.slice(1);

        if (titlee.includes("associados")) return "Associados";
        if (titlee.includes("/configuracoes/empresa/")) return "Empresa";
        if (titlee.includes("adicao") && titlee.includes("importacao")) return "Adição";
        if (titlee.includes("importacao")) return "Processo Importação";


        for (var i = 0; i < this.listTitles.length; i++) {
            var parent = this.listTitles[i];
            if (parent.path === titlee) return parent.title;
            else if (parent.children) {
                var children_from_url = titlee.split("/")[2];
                for (var j = 0; j < parent.children.length; j++) if (parent.children[j].path === children_from_url) return parent.children[j].title;
            }
        }
        return 'Dashboard';
    }

    getPath() {
        return this.location.prepareExternalUrl(this.location.path());
    }

    onSubmitLogout() {
        this.serviceAuth.logout();
    }

    onGetMoedas() {
        this.genericHttpService.get('api/Moeda').subscribe({
            next: (response) => {
                this.listMoedas = response.filter(o => o.sigla === 'EUR' || o.sigla === 'USD' || o.sigla === 'NOK');
                console.log(this.listMoedas)
            },
            error: (err) => {
                console.log(err);
            }
        })
    }

    formatCurrency(vl) {
        const formattedNumber = new Intl.NumberFormat('pt-BR', {
            style: 'currency',
            currency: 'BRL',
            minimumFractionDigits: 4,
            maximumFractionDigits: 4
        }).format(vl);
        return formattedNumber.replace(/^R\$\s*/g, '');
    }
}
